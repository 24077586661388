import React from "react";
import {HeadFC} from "gatsby";
import {Header} from "../components/header";
import {Footer} from "../components/footer";
import {useParosSkeletonStyle} from "../hooks/use_paros_skeleton";
import {SEO} from "../components/seo";

const CancellationPolicyPage = () => {
  return (
    <>
      <Header/>
      <main className="h-full">
        <section className="h-full sm:h-1/5 max-w-6xl mx-auto pt-10 pb-14" style={useParosSkeletonStyle()}>
          <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
            <h1 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 font-poppins">
              Cancellation Policy
            </h1>
          </div>
        </section>
        <section className="w-full bg-white py-20 px-8">
          <div className="max-w-6xl mx-auto">
            <h2 className="text-3xl mb-2"><b>Cancellation Policy</b></h2>
            <p>We understand that sometimes plans change. Here is our cancellation policy:</p>
            <ul className="list-disc ml-8 mt-2">
              <li>We accept any cancellation of a car ten (10) days prior to your arrival without any charge.</li>
              <li>For any cancellation less than ten (10) days, 20% of the total rental is withheld.</li>
              <li>Cancellations (2) days prior to arrival 80% of the total rental will be withheld.</li>
              <li>If you do not show up for pickup, you will be charged the total amount of the rental.</li>
            </ul>
            <p className="mt-4">
              We strive to be flexible with our customers and understand that plans can change. If you
              need to make changes to your reservation, we allow changes for time, date, and car category up to 24 hours
              before the rental. Please contact us as soon as possible to make any changes.
            </p>
          </div>
        </section>
      </main>
      <Footer/>
    </>
  )
}

export const Head: HeadFC = () => (
  <SEO
    title="Coolcar Cancellation Policy | Cancel Your Rental With Ease"
    description="Discover Coolcar Rentals' Cancellation Policy for Paros car rentals. No fee for cancellations 7 days prior. Book confidently & make changes up to 24h before rental."
  />
)

export default CancellationPolicyPage;